<template>
    <div class="chooseContainer">
        <div class="chooseProduct">
            <div id="chooseSearch" class="chooseType" @click="prelink(0)">
                <img src="../../public/icon_search.svg" class="imgSearch">
                <h3 class="chooseTextAnonyma">Search</h3>
                <div class="chooseDescription">{{ $t("selectProduct.search") }}</div>
            </div>
            <div id="chooseSingleSigner" class="chooseType" @click="prelink(1)">
                <img src="../../public/icon_sign.svg" class="imgSign">
                <h3 class="chooseTextAnonyma">SingleSign</h3>
                <div class="chooseDescription">{{ $t("selectProduct.singleSign") }}</div>
            </div>
            <div id="chooseMultiSigner" class="chooseType" @click="prelink(2)">
                <img src="../../public/icon_multiSign2.svg" class="imgUpload">
                <h3 class="chooseTextAnonyma">MultiSign</h3>
                <div class="chooseDescription">{{ $t("selectProduct.multiSign") }}</div>
            </div>
            <div id="chooseAnonyma" class="chooseType" @click="prelink(3)">
                <img src="../../public/icon_anonyma.svg" class="imgDocuments">
                <h3 class="chooseTextAnonyma">Anonyma</h3>
                <div class="chooseDescription">{{ $t("selectProduct.anonyma") }}</div>
            </div>
            <div id="chooseCompare" class="chooseType" @click="prelink(4)">
                <img src="../../public/icon_compare.svg" class="imgAnonyma">
                <h3 class="chooseTextAnonyma">PDFCompare</h3>
                <div class="chooseDescription">{{ $t("selectProduct.compare") }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import EnvProvider from 'jvjr-docker-env';

export default {
    name: "SelectProduct",
    data () {
        return {
        }
    },
    methods: {
        prelink(id) {
            console.log(id);
            switch (id) {
                case 0:
                    window.location.href = EnvProvider.value('URL_SEARCH');
                    break;
                case 1:
                    window.location.href = EnvProvider.value('URL_SIGNER') + '/single-sign';
                    break;
                case 2:
                    window.location.href = EnvProvider.value('URL_SIGNER');
                    break;
                case 3:
                    window.location.href = EnvProvider.value('URL_ANONYMA');
                    break;
                case 4:
                    window.location.href = EnvProvider.value('URL_COMPARE');
                    break;
                default:
                    console.log('Unknown prelink ID');
            }
        },
    }
}
</script>


<style scoped>

.chooseContainer 
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 2rem;
    height: 93vh;
}

.chooseProduct {
    display: inline-flex;
    /* justify-content: space-evenly; */
     /* padding-top: 2%; */
    color: #105EA9;
    column-gap: 2rem;
    row-gap: 2rem;
    margin-top: 2rem;
    justify-content: center;
    flex-wrap: wrap;
}

.chooseType:hover 
{
    cursor: pointer;
    box-shadow: rgba(57, 57, 57, 0.2) 0px 2px 8px 0px;
}


.chooseProduct:last-child 
{
    
    margin-bottom: 2rem;
}

.chooseProduct img 
{
    height: 90px;
}

.chooseIcons {
    text-align: center;
    padding: 15px;
    border-radius: 15px;

}

.chooseDescription {
    max-width: 300px;
    color: #9d9d9d;
    margin-inline: 1rem;            
    font-size: 14px;
}

.chooseSearch {
    background-color: #2156A4;
    color: white;
}

.chooseAnonyma {
    background-color: white;
    color: #2156A4;
}

.chooseType {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 2rem 2rem;;
    width: 18rem;
    background: #E9E9E9;
    align-items: center;
    justify-content: flex-end;
    border-radius: 28px;
}

.chooseText {
    font-size: 26px;
    margin: 15px;
}
.chooseTextAnonyma {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-inline: 5rem;
    margin-block: 2rem;
}


#chooseSearch:hover, #chooseAnonyma:hover, #chooseSigner:hover{
    color: #4a87c0;
}
.chooseSearch {
    background-color: #4a87c0;
}

/* highlighting neeed to be splitted and change names */
.modalExport {
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalExport > div {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
}

.modalSignerViewExport {
    width: 70%;
    height: 95%;
    font-family: 'Poppins', sans-serif;;
    overflow-y: auto;
}

.highlightHeaderContent {
    background-color: lightgrey;
    margin: 5px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.highlightTitle {
    width: 97%;
    text-align: center;
}

#closeHighlight {
    text-align: center;
    width: 3%;
    height: 3%;
    background-color: darkgray;
    border-radius: 5px;
    margin-right: 1%;
}

.signerIframe {
    height: 90%;
    margin: 5px;
}

.signer {
    width: 100%;
    height: 100%;
    border: none;
}


</style>